import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSalePersonData } from "../../../Redux/features/SalesByPerson/SalesByPersonSlice";
import { Link, useNavigate } from "react-router-dom";

import { useAuthDetails } from "../../../Common/cookiesHelper";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Tooltip, tooltipClasses } from "@mui/material";
import { priceFormate } from "../../../hooks/priceFormate";
import SortIconW from "../../../Assests/Category/SortingW.svg";
import { SortTableItemsHelperFun } from "../../../helperFunctions/SortTableItemsHelperFun";
import PasswordShow from "../../../Common/passwordShow";
import Skeleton from "react-loading-skeleton";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import useDelayedNodata from "../../../hooks/useDelayedNoData";
import { LuInfo } from "react-icons/lu";
// ==================== TABLE STYLE ADDED ===================================================
const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f9",
    "&::before": {
      border: "1px solid #dadde9",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "& td, & th": {
    border: "none",
  },
}));
// ==================== END TABLE STYLE ADDED ===================================================
const orderType = (type) => {
  if (type === "Online Order") {
    return "Online";
  }
  if (type === "Store Order") {
    return "Offline";
  } else {
    return type;
  }
};
const SalesPersonReport = (props) => {
  const {
    LoginGetDashBoardRecordJson,
    LoginAllStore,
    userTypeData,
    GetSessionLogin,
  } = useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" for ascending, "desc" for descending

  const dispatch = useDispatch();
  const [allSalesByPersonData, setallSalesByPersonData] = useState("");
  //const showNoData = useDelayedNodata(Object.entries(allSalesByPersonData));
  const showNoData = !Object.entries(allSalesByPersonData).some(
    ([key, { order_data }]) => order_data?.length > 0
  );

  const AllSalesByPersonDataState = useSelector(
    (state) => state.SalesByPersonList
  );
  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  const [totalRecord, setTotalRecord] = React.useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getSalePersonData();
  }, [
    props.selectedDateRange,
    props.OrderSourceData,
    props.OrderTypeData,
    props.SelectEmpListData,
  ]);
  const getSalePersonData = async () => {
    try {
      if (props && props.selectedDateRange) {
        let payTypeValue;
        if (orderType(props.OrderSourcePayData) === "Cash & Card") {
          payTypeValue = 0;
        } else if (orderType(props.OrderSourcePayData) === "Cash") {
          payTypeValue = 2;
        } else if (orderType(props.OrderSourcePayData) === "Card") {
          payTypeValue = 1;
        }
        let data = {
          merchant_id,
          start_date: props.selectedDateRange.start_date,
          end_date: props.selectedDateRange.end_date,
          order_typ: props.OrderTypeData,
          order_env: orderType(props.OrderSourceData),
          // pay_type: payTypeValue,
          employee_id: props.SelectEmpListData,
          ...userTypeData,
        };
        if (data) {
          await dispatch(fetchSalePersonData(data)).unwrap();
        }
      }
    } catch (error) {
      if (error.status == 401 || error.response.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  useEffect(() => {
    if (
      !AllSalesByPersonDataState.loading &&
      AllSalesByPersonDataState.SalePersonData
    ) {
      setallSalesByPersonData(AllSalesByPersonDataState.SalePersonData);
      props.setCSVHeader([
        { label: "Employee Name", key: "EmpName" },
        { label: "Order ID", key: "order_id" },
        { label: "Date", key: "merchant_time" },
        { label: "Total", key: "amt" },
      ]);
      const csvData = Object.entries(
        AllSalesByPersonDataState?.SalePersonData
      )?.flatMap(([key, { final_card, final_cash,final_other_refund, order_data }]) => {
        // Map individual items first
        if(order_data.length >0){
          
          const itemRows = order_data?.map((item, index) => ({
            EmpName: index === 0 ? key : "",
            order_id: `'${item.order_id}`,
            merchant_time: formatDateTime(item.merchant_time),
            amt: item.amt,
          }));
          // Add the total row for each category
          const totalRow = {
            EmpName: "Total",
            name: "",
            amt: order_data.reduce((acc, item) => acc + parseFloat(item.amt), 0),
          };
          const CashRow = {
            EmpName: "Card",
            order_id: final_card.toFixed(2),
            merchant_time: "Cash",
            amt: final_cash.toFixed(2),
          };
  
          // Return all individual rows plus the total row
          return [...itemRows, totalRow,CashRow];
        }
        return [];
      });

      const grandTotalForCSV = Object.values(AllSalesByPersonDataState?.SalePersonData)?.reduce(
        (acc, { order_data }) => acc + order_data.reduce((accOrder, item) => accOrder + parseFloat(item.amt), 0),
        0
      );
      
      props.setCSVData([
        ...csvData,
        {
          EmpName: "Grand Total",
          pro_qty: "grandTotalProductQtyForCSV",
          amt: grandTotalForCSV.toFixed(2),
        },
      ]);
    } else {
      setallSalesByPersonData("");
    }
  }, [
    AllSalesByPersonDataState,
    AllSalesByPersonDataState.loading,
    AllSalesByPersonDataState.ItemSalesData,
  ]);

  useEffect(() => {
  }, [allSalesByPersonData]);

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    return `${formattedDate} ${formattedTime}`;
  };

  let grandTotal = 0;
  Object.values(AllSalesByPersonDataState.SalePersonData).forEach(EmpData => {
    if (Array.isArray(EmpData.order_data)) {  // Check if 'order_data' is an array
      EmpData.order_data.forEach(order => {
        grandTotal += Number(order.amt) || 0; // Convert amt to number and handle non-numeric gracefully
      });
    }
  });

  const sortByItemName = (type, name) => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    const updatedCategorySale = Object.fromEntries(
      Object.entries(allSalesByPersonData).map(([key, { final_card, final_cash, order_data }]) => {
        const { sortedItems } = SortTableItemsHelperFun(
          order_data,
          type,
          name,
          sortOrder
        );
        return [key, { final_card, final_cash, order_data: sortedItems }];
      })
    );

    setallSalesByPersonData(updatedCategorySale);
    setSortOrder(newOrder);
  };
  
  const formatCurrency = (amount) => {
    const formattedAmount = Math.abs(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return amount < 0 ? `-${formattedAmount}` : formattedAmount;
  };
  return (
    <>
      {AllSalesByPersonDataState.loading ? (
        <Grid container className="box_shadow_div">
          <Grid item xs={12}>
            <div className="q-category-bottom-header">
              <div className="q_details_header ml-2">
                <Skeleton />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <SkeletonTable columns={["Order ID", "Date", "Total"]} />
          </Grid>
        </Grid>
      ) :
      ( Object.entries(allSalesByPersonData).length > 0 && !showNoData)
       
       ? (
        <>
        {Object.entries(allSalesByPersonData).map(([key, { final_card, final_cash,final_other_refund, order_data }]) => (
          <>
            { order_data?.length > 0 ? (
              <>
              <Grid container className="box_shadow_div">
              <Grid item xs={12}>
                <div className="q-category-bottom-header">
                  <div className="q_details_header ml-2">
                    Employee Name: {key}
                  </div>
                </div>
                <TableContainer>
                  <StyledTable
                    sx={{ minWidth: 500 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("id", "order_id")}
                        >
                          <p>Order ID</p>
                          <img src={SortIconW} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() =>
                            sortByItemName("date", "merchant_time")
                          }
                        >
                          <p>Date</p>
                          <img src={SortIconW} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell align="center" colSpan={2}>
                        <button
                          className=""
                          onClick={() => sortByItemName("num", "amt")}
                        >
                          <div className="flex items-center">
                            {" "}
                            <p>Total</p>
                            <img src={SortIconW} alt="" className="pl-1" />
                          </div>
                        </button>
                      </StyledTableCell>
                    </TableHead>
                    <TableBody>
                          {order_data?.map((SalesData, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell sx={{ width: "33%" }}>
                                {/* <span
                                  className="cursor-pointer text-[#0A64F9]"
                                  onClick={() =>
                                    navigate(
                                      `/order/store-reporting/order-summary/${merchant_id}/${SalesData.order_id}`
                                    )
                                  }
                                >
                                  {SalesData.order_id}
                                </span> */}

                                <Link className="cursor-pointer text-[#0A64F9]"
                                  to={`/order/store-reporting/order-summary/${merchant_id}/${SalesData.order_id}`}
                                  target="_blank"
                                >{SalesData.order_id}</Link>

                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  borderRight: "1px solid #E3E3E3",
                                  width: "33%",
                                }}
                              >
                                <p>{formatDateTime(SalesData.merchant_time)}</p>
                              </StyledTableCell>
                              <StyledTableCell align="center" sx={{ width: "16.5%" }} colSpan={2}>
                                <p> {formatCurrency((parseFloat(SalesData.amt) || 0).toFixed(2))}</p>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}


                      <StyledTableRow className="trBG_Color BORDERDOWNTableCell">
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell >
                          <div className=" totalReport">
                            <div>Total </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={2}>
                          <div className="totalReport">
                            <div>
                              {/* {formatCurrency((parseFloat(final_cash || 0) + parseFloat(final_card || 0)).toFixed(2))} */}
                              {formatCurrency(
                                  order_data.reduce(
                                    (acc, SalesData) => acc + (parseFloat(SalesData.amt) || 0), 0
                                  ).toFixed(2)
                                )}
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow className=" ">
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell >
                          <div className=" totalReport">
                             Card : {formatCurrency((parseFloat(final_card || 0)).toFixed(2))}
                            </div>
                        </StyledTableCell>
                        <StyledTableCell align="center" colSpan={2}>
                          <div className="totalReport whitespace-nowrap">
                                  Cash : {formatCurrency((parseFloat(final_cash || 0)).toFixed(2))}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </Grid>
            </Grid>

                
              </>  ):("")
            }
          </>
        )) }
        </>
      ) : (
        <Grid sx={{ pt: 2.5 }}>{showNoData && <NoDataFound />}</Grid>
      )}

        { !showNoData && Object.entries(allSalesByPersonData).length > 0 && (
                    <StyledTable
                      sx={{ minWidth: 500 }}
                      aria-label="customized table"
                      style={{ marginBottom: "1rem", transform: "translate(0rem, -1rem)" }}
                    >
                      <StyledTableRow className="trBG_Color">
                        <StyledTableCell sx={{ width: "33%" }}></StyledTableCell>
                        <StyledTableCell  sx={{ width: "33%" }}  align="center">
                          <div className="q-category-bottom-report-listing">
                            <div>
                              <p className="totalReport">Grand Total</p>
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className="q-category-bottom-report-listing">
                            <div>
                              <p className="totalReport">
                                ${priceFormate(grandTotal.toFixed(2))}
                              </p>
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </StyledTable>
          )}
    </>
  );
};

export default SalesPersonReport;

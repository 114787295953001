import React from 'react';
import SettingStoreOption from './SettingStoreOption';

const MainStoreOption = () => {
    return (
      <>
      <div className=''>
        <SettingStoreOption />
        </div>
      </>
    )
  }
  
  export default MainStoreOption